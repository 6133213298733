import { Route } from 'react-router-dom';

import GuardedRouteByRole from '../../hoc/guardedRouteByRole';
import DashboardLayout from '../../layouts/DashboardLayout';
import ClientsScreen from '../../screens/clients/ClientsScreen';
import ConfigurationsScreen from '../../screens/configurations/ConfigurationsScreen';
import ConsumersScreen from '../../screens/consumers/ConsumersScreen';
import DashboardScreen from '../../screens/dashboard/DashboardScreen';
import Error404Screen from '../../screens/error/Error404Screen';
import ErrorNoAccessScreen from '../../screens/error/ErrorNoAccessScreen';
import ProductsScreen from '../../screens/products/ProductsScreen';
import ProjectScreen from '../../screens/projects/ProjectScreen';
import ProjectsScreen from '../../screens/projects/ProjectsScreen';
import RelayScreen from '../../screens/relay/RelayScreen';
import SwitchboardScreen from '../../screens/switchboard/SwitchboardScreen';
import TermsAndConditionsScreen from '../../screens/termsAndConditions/TermsAndConditionsScreen';
import UsersManagementScreen from '../../screens/userManagement/UsersManagementScreen';
import ManageAccess from '../../screens/usersAccess/ManageAccess';
import X7Screen from '../../screens/x7/X7Screen';
import { PermissionType } from '../enums/permissionTypeEnum';

export const dashBoardRoutes = ({ isAdmin, userPermissions }) => {
  return (
    <>
      <Route path="/*" element={<DashboardLayout />}>
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              userPermissions={userPermissions}
              permissions={[PermissionType.DASHBOARD.VIEW]}
            />
          }
        >
          <Route index element={<DashboardScreen />} />
        </Route>
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              permissions={[
                PermissionType.CLIENT.VIEW,
                PermissionType.CLIENT.MODIFY
              ]}
              userPermissions={userPermissions}
            />
          }
        >
          <Route path="clients" element={<ClientsScreen />} />
        </Route>
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              userPermissions={userPermissions}
              permissions={[
                PermissionType.PROJECTS.MODIFY,
                PermissionType.PROJECTS.VIEW
              ]}
            />
          }
        >
          <Route path="projects" element={<ProjectsScreen />} />
        </Route>
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              permissions={[
                PermissionType.PROJECTS.MODIFY,
                PermissionType.PROJECTS.VIEW,
                PermissionType.PROJECT_DETAILS.VIEW,
                PermissionType.PROJECT_DETAILS.MODIFY
              ]}
              userPermissions={userPermissions}
            />
          }
        >
          <Route path="projects/:projectId" element={<ProjectScreen />} />
          <Route
            path="projects/:projectId/switchboard"
            element={<SwitchboardScreen />}
          />
          <Route path="projects/:projectId/x7" element={<X7Screen />} />
          <Route path="projects/:projectId/relay" element={<RelayScreen />} />
        </Route>
        <Route path="pages/notifications" element={<Error404Screen />} />
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              permissions={[
                PermissionType.CONFIGURATIONS.MODIFY,
                PermissionType.CONFIGURATIONS.VIEW
              ]}
              userPermissions={userPermissions}
            />
          }
        >
          <Route path="configurations" element={<ConfigurationsScreen />} />
        </Route>
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              permissions={[
                PermissionType.CONSUMER.VIEW,
                PermissionType.CONSUMER.MODIFY
              ]}
              userPermissions={userPermissions}
            />
          }
        >
          <Route path="consumers" element={<ConsumersScreen />} />
        </Route>
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              permissions={[
                PermissionType.PRODUCT.MODIFY,
                PermissionType.PRODUCT.VIEW
              ]}
              userPermissions={userPermissions}
            />
          }
        >
          <Route path="products" element={<ProductsScreen />} />
        </Route>
        <Route
          element={
            <GuardedRouteByRole
              isAdmin={isAdmin}
              permissions={[
                PermissionType.USER_MANAGEMENT.MANAGE_USER_ROLES.VIEW,
                PermissionType.USER_MANAGEMENT.MANAGE_USER_ROLES.MODIFY
              ]}
              userPermissions={userPermissions}
            />
          }
        >
          <Route path="manage-access" element={<ManageAccess />} />
        </Route>
        <Route element={<GuardedRouteByRole isAdmin={isAdmin} />}>
          <Route path="manage-users" element={<UsersManagementScreen />} />
        </Route>
        <Route path="terms" element={<TermsAndConditionsScreen />} />
        <Route path="no-access" element={<ErrorNoAccessScreen />} />
        <Route path="*" element={<Error404Screen />} />
      </Route>
    </>
  );
};
