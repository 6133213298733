import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label
} from 'reactstrap';

import RequiredStarLabel from '../common/requiredStarLabel';
import FormGroupNote from './FormGroupNote';

export default function InputFormFieldFloatValue({
  isDisabled,
  isRequired = false,
  label,
  displayLabel,
  inputType = 'text',
  inputGroupText = null,
  placeholder,
  inputClassName,
  isDecimal = true
}) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const handleInputChangeValueToFloat = (e, onChange) => {
    const value = e.target.value;

    if (isDecimal) {
      if (!value || value.match(/^\d+(\.\d{0,2})?$/)) {
        onChange(value);
      }
    } else {
      if (!value || value.match(/^\d+$/)) {
        onChange(value);
      }
    }
  };

  return (
    <FormGroup>
      <Label htmlFor={label}>
        {t(displayLabel)} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={label}
        control={control}
        render={({ field }) => (
          <InputGroup>
            <Input
              {...field}
              type={inputType}
              id={label}
              disabled={isDisabled}
              placeholder={placeholder && t(placeholder)}
              className={`${inputClassName ?? ''}`}
              onChange={(e) => handleInputChangeValueToFloat(e, field.onChange)}
              value={field.value ?? ''}
              autoComplete="off"
            />
            {inputGroupText && (
              <InputGroupText>{t(inputGroupText)}</InputGroupText>
            )}
          </InputGroup>
        )}
      />
      {errors[label] && (
        <FormGroupNote type={'warning'}>{errors[label].message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
