import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import InputFormField from '../../../../components/form/InputFormField';
import ProductManufacturerSelect from '../../../../components/form/ProductModalForm/MainFields/ProductManufacturerSelect';
import ConnectionTypeSelect from '../../../../components/form/ProductModalForm/ProtectionRelatedFields/ConnectionTypeSelect';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { switchboardApi } from '../../../../helpers/api/switchboard/switchboardApi';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { ProductDestination } from '../../../../helpers/enums/productDestinationEnum';
import { switchboardSettingsFormSchema } from '../../../../helpers/formValidations/switchboardSettingsFormSchema';
import { getSwitchboardSettingsFormValues } from '../../../../helpers/utils/getSwitchboardSettingsFormValues';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import { useFormScrollIntoView } from '../../../../hooks/useFormScrollIntoView';
import CablesHaveTileLabelCheckbox from '../form/CablesHaveTileLabelCheckbox';
import ContactorProductSelect from '../form/ContactorProductSelect';
import ProtectionSelect from '../form/ProtectionSelect';
import RelayProductSelect from '../form/RelayProductSelect';
import SwitchboardMountTypeSelect from '../form/SwitchboardMountTypeSelect';
import SwitchboardParametersFields from '../form/SwitchboardParametersFields';
import SwitchboardProductsFields from '../form/SwitchboardProductFields';
import CardModal from './CardModal';

const SwitchboardSettingsModal = ({
  isOpen,
  toggle,
  switchboard,
  refreshSwitchboard
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: getSwitchboardSettingsFormValues(switchboard),
    mode: 'onChange',
    resolver: zodResolver(switchboardSettingsFormSchema)
  });

  useFormScrollIntoView({ form });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const newData = {
        ...getSwitchboardSettingsFormValues(data),
        protection: data.protection.id
      };
      await switchboardApi.updateSwitchboardsByProjectId(
        switchboard.projectId,
        newData
      );
      toast.success(t('Switchboard settings successfully saved.'));
      refreshSwitchboard();
      toggle();
      form.reset();
    } catch (error) {
      const errors = error?.response?.data?.errors;
      if (
        errors &&
        errors.length > 0 &&
        errors[0]?.propertyName === 'switchboardHeight'
      ) {
        form.setError('switchboardHeight', {
          message: errors[0]?.errorMessage
        });
      } else {
        showErrorsFromRequest(error, t);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      form.reset({
        ...getSwitchboardSettingsFormValues(switchboard)
      });
    }
  }, [isOpen]);

  return (
    <CardModal
      title="Switchboard Settings"
      isOpen={isOpen}
      toggleModal={toggle}
    >
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="form-modal-padding">
            <InputFormField
              isDisabled={isLoading}
              label="label"
              displayLabel="Label"
              isRequired
            />
            <ConnectionTypeSelect isRequired isDisabled={isLoading} />
            <ProductManufacturerSelect
              isDisabled={switchboard.hasBeenSet || isLoading}
            />
            <RelayProductSelect isDisabled={isLoading} />
            <ContactorProductSelect isDisabled={isLoading} />
            <ProtectionSelect
              destination={ProductDestination.SWITCHBOARD}
              manufacturerId={form.watch('manufacturerId')}
              isDisabled={isLoading}
            />
            <SwitchboardMountTypeSelect isRequired isDisabled={isLoading} />
            <InputFormField
              isDisabled={isLoading}
              label="forceCableCode"
              displayLabel="Force cable code"
              isRequired
            />
            <InputFormField
              isDisabled={isLoading}
              label="busCableCode"
              displayLabel="Bus cable code"
              isRequired
            />
            <InputFormField
              isDisabled={isLoading}
              label="networkCableCode"
              displayLabel="Network cable code"
              isRequired
            />
            <CablesHaveTileLabelCheckbox isDisabled={isLoading} />
            <SwitchboardParametersFields isLoading={isLoading} />
            <SwitchboardProductsFields isDisabled={isLoading} />
            <CheckUserPermissions
              permissions={[PermissionType.SWITCHBOARD.MODIFY_DETAILS]}
            >
              <Button type="submit" disabled={isLoading} color="falcon-primary">
                {t('Save')}
              </Button>
            </CheckUserPermissions>
          </div>
        </Form>
      </FormProvider>
    </CardModal>
  );
};

export default SwitchboardSettingsModal;
