import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MountType } from '../../../../../helpers/enums/mountTypeEnum';
import { getNullSwitchboardProductFormValues } from '../../../../../helpers/utils/getSwitchboardSettingsFormValues';
import BlindPlate from './BlindPlate';
import DistributionBoardSelect from './DistributionBoardSelect';
import FrameSelect from './FrameSelect';
import MeterBoxPlateSelect from './MeterBoxPlateSelect';
import MeterBoxSelect from './MeterBoxSelect';
import RailSelect from './RailSelect';
import RearPanelSelect from './RearPanelSelect';
import ReservedBlindPlate from './ReservedBlindPlate';
import SlottedPlate from './SlottedPlate';
import SupportSelect from './SupportSelect';

export default function SwitchboardProductsFields({ isDisabled }) {
  const { t } = useTranslation();

  const { watch, clearErrors, resetField } = useFormContext();

  const hasCounterTub = watch('hasCounterTub');
  const mountType = watch('mountType');
  const isAllIncluded = watch('isAllIncluded');

  const [previousMountType, setPreviousMountType] = useState(null);

  useEffect(() => {
    if (mountType !== previousMountType && previousMountType !== null) {
      resetField('switchboardProduct', {
        defaultValue: getNullSwitchboardProductFormValues()
      });
      clearErrors();
    }

    setPreviousMountType(mountType);
  }, [clearErrors, mountType, previousMountType, resetField]);

  useEffect(() => {
    if (!hasCounterTub) {
      resetField('switchboardProduct.counter', { defaultValue: null });
      resetField('switchboardProduct.counterPlate', { defaultValue: null });
    }
  }, [hasCounterTub, resetField]);

  if (mountType === null) {
    return null;
  }

  return (
    <div>
      <h6 className="mt-4 text-primary font-weight-bold d-flex gap-2">
        {t('Switchboard Products')}
      </h6>
      <DistributionBoardSelect isDisabled={isDisabled} />
      {mountType === MountType.WallMount && (
        <>
          <RearPanelSelect isDisabled={isDisabled} />
        </>
      )}
      {mountType === MountType.FlushMount && (
        <>
          <FrameSelect isDisabled={isDisabled} />
        </>
      )}
      <SupportSelect isDisabled={isDisabled} />
      <RailSelect isDisabled={isDisabled} />
      {!isAllIncluded && (
        <>
          <SlottedPlate isDisabled={isDisabled} />
          <BlindPlate isDisabled={isDisabled} />
          <ReservedBlindPlate isDisabled={isDisabled} />
        </>
      )}
      {hasCounterTub && (
        <>
          <MeterBoxSelect isDisabled={isDisabled} />
          <MeterBoxPlateSelect isDisabled={isDisabled} />
        </>
      )}
    </div>
  );
}
