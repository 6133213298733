import { Outlet } from 'react-router-dom';

import NotAuthComponent from '../screens/auth/components/NotAuthComponent';

const GuardedRouteByRole = ({ isAdmin, userPermissions, permissions }) => {
  const hasAnyPermission = () => {
    return permissions && permissions.some((permission) => userPermissions.includes(permission));
  };

  if (isAdmin || hasAnyPermission()) {
    return <Outlet />;
  }

  return <NotAuthComponent />;
};

export default GuardedRouteByRole;
