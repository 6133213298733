import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotAuthComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate('/auth/login');
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return <div></div>;
}
