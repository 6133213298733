import {
  CategorySortBy,
  ClientSortBy,
  ProductSortBy,
  ProjectSortBy
} from '../helpers/enums/sortEnum';

export const CategorySortOptions = [
  {
    value: CategorySortBy.Name,
    label: 'Name'
  },
  {
    value: CategorySortBy.CreatedAt,
    label: 'Created At'
  }
];

export const ClientSortOptions = [
  {
    value: ClientSortBy.Id,
    label: 'Id'
  },
  {
    value: ClientSortBy.Name,
    label: 'Name'
  },
  {
    value: ClientSortBy.Email,
    label: 'Email'
  },
  {
    value: ClientSortBy.Phone,
    label: 'Phone'
  },
  {
    value: ClientSortBy.CreatedAt,
    label: 'Created At'
  }
];

export const ProductSortOptions = [
  {
    value: ProductSortBy.Sku,
    label: 'Sku'
  },
  {
    value: ProductSortBy.Label,
    label: 'Label'
  },
  {
    value: ProductSortBy.Type,
    label: 'Type'
  },
  {
    value: ProductSortBy.Manufacturer,
    label: 'Manufacturer'
  },
  {
    value: ProductSortBy.ManufacturerSku,
    label: 'Manufacturer Sku'
  },
  {
    value: ProductSortBy.Price,
    label: 'Price'
  }
];

export const ProjectSortOptions = [
  {
    value: ProjectSortBy.UpdatedAt,
    label: 'Updated At'
  },
  {
    value: ProjectSortBy.Name,
    label: 'Name'
  },
  {
    value: ProjectSortBy.FinanceDiscount,
    label: 'Finance Discount'
  },
  {
    value: ProjectSortBy.FinanceHourlyRate,
    label: 'Finance Hourly Rate'
  },
  {
    value: ProjectSortBy.Status,
    label: 'Status'
  }
];
