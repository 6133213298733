import PropTypes from 'prop-types';
import React from 'react';
import { Progress } from 'reactstrap';

/**
 * Project card progress bar
 *
 * @param {*} param0
 */
const ProgressBar = ({ progress }) => {
  return (
    <>
      <div className="fs--1 m-1"> {progress}% </div>
      <Progress className="mb-2" value={progress} color={progress < 100 ? '' : 'success'} style={{ height: '5px' }} />
    </>
  );
};

ProgressBar.propTypes = {
    progress: PropTypes.number
};

ProgressBar.defaultProps = { progress: 0 };

export default ProgressBar;
