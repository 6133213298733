import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, InputGroup } from 'reactstrap';

import FalconCardHeader from '../../../components/common/FalconCardHeader';
import SearchInput from '../../../components/SearchInput';
import { productSelectDestinationOptions } from '../../../constants/productSelectDestinationOptions';
import { productSelectGroupOptions } from '../../../constants/productSelectGroupOptions';
import ProductsFilterSelect from './ProductsFilterSelect';

export default function ProductsTableHeader() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const group = searchParams.get('group') || '';
  const destination = searchParams.get('destination') || '';
  const search = searchParams.get('search') || '';

  const [searchValue, setSearchValue] = useState(search);

  const setFilterParams = (value, name) => {
    if (value === '') {
      searchParams.delete(name);
      setSearchParams(searchParams);
      return;
    }

    setSearchParams((params) => {
      params.set(name, value);
      return params;
    });
  };

  const handleProductSearch = async (value) => {
    setFilterParams(value, 'search');
    setFilterParams(1, 'page');
  };

  const handleResetFilters = () => {
    setFilterParams('', 'group');
    setFilterParams('', 'destination');
  };

  return (
    <div className="d-flex align-items-end search-with-filter px-4 py-3 flex-wrap gap-2">
      <SearchInput
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onSearch={(value) => handleProductSearch(value)}
        placeholder="Label, SKU, Manufacturer SKU"
      />
      <FalconCardHeader title="" light={false} className="p-0 pb-1">
        <div className="d-flex align-items-center filter-input-group-with-reset gap-2">
          <InputGroup size="sm" className="input-group input-group-sm gap-2">
            <ProductsFilterSelect
              selectId="groupSelect"
              defaultValue="Filter by Group"
              onChange={(value) => setFilterParams(value, 'group')}
              options={productSelectGroupOptions}
              value={group}
            />
            <ProductsFilterSelect
              selectId="destinationSelect"
              defaultValue="Filter by Destination"
              onChange={(value) => setFilterParams(value, 'destination')}
              options={productSelectDestinationOptions}
              value={destination}
            />
          </InputGroup>
          <Button
            className="d-flex align-items-center gap-1"
            size="sm"
            onClick={handleResetFilters}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
            <span>{t('Reset')}</span>
          </Button>
        </div>
      </FalconCardHeader>
    </div>
  );
}
