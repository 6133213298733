import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import { deleteConfirmationToast } from '../../../../components/deleteConfirmationToast';
import IndexFieldSelect from '../../../../components/form/IndexFieldSelect';
import InputFormField from '../../../../components/form/InputFormField';
import AppContext from '../../../../context/Context';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { switchboardSubzoneApi } from '../../../../helpers/api/switchboard/switchboardSubzoneApi';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { ProductDestination } from '../../../../helpers/enums/productDestinationEnum';
import { switchboardSubzoneFormSchema } from '../../../../helpers/formValidations/switchboardSubzoneFormSchema';
import { checkUserPermissions } from '../../../../helpers/utils/checkUserPermissions';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import { useFormScrollIntoView } from '../../../../hooks/useFormScrollIntoView';
import ProtectionSelect from './ProtectionSelect';

export default function SubzoneModalForm({
  defaultValues,
  isOpen,
  toggle,
  subzone,
  switchboard,
  refreshSwitchboard,
  isEdit,
  subzones
}) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(switchboardSubzoneFormSchema)
  });

  useFormScrollIntoView({ form });

  useEffect(() => {
    if (isOpen) {
      form.reset({
        index: subzone.index,
        label: subzone.label ?? '',
        protection: subzone.protection ?? null
      });
    }
  }, [isOpen]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const newData = {
        ...data,
        protection: data.protection.id,
        zoneId: subzone.zoneId
      };
      if (isEdit) {
        await switchboardSubzoneApi.updateSubzone(
          switchboard.projectId,
          subzone.id,
          newData
        );
      } else {
        await switchboardSubzoneApi.addSubzone(switchboard.projectId, newData);
      }
      toast.success(t('Subzone successfully saved.'));
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    try {
      await switchboardSubzoneApi.deleteSubzone(
        switchboard.projectId,
        subzone.id
      );
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = () => {
    deleteConfirmationToast({
      title: `${'Please confirm you want to delete'} "${subzone.label}" ${t('subzone')} ?`,
      onClick: () => onDelete(),
      buttonText: t('Yes, delete!')
    });
  };

  const checkPermission = (permissions) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions
    });
  };

  const restoreDeletedSubzone = async () => {
    try {
      await switchboardSubzoneApi.restoreSubzone(
        switchboard.projectId,
        subzone.id
      );
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const isZoneDeleted = switchboard.zone(subzone.zoneId)?.isDeleted || false;

  const checkIsDeleted = isZoneDeleted || subzone.zoneIsDeleted;

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <IndexFieldSelect
          items={subzones}
          isEdit={isEdit}
          isDisabled={
            isLoading ||
            checkIsDeleted ||
            !checkPermission([PermissionType.SWITCHBOARD.SUBZONE.VIEW])
          }
        />
        <InputFormField
          isDisabled={
            isLoading ||
            checkIsDeleted ||
            !checkPermission([PermissionType.SWITCHBOARD.SUBZONE.VIEW])
          }
          label="label"
          placeholder="eg. First Floor"
          displayLabel="Label"
          isRequired
        />
        <ProtectionSelect
          isDisabled={
            isLoading ||
            checkIsDeleted ||
            !checkPermission([PermissionType.SWITCHBOARD.SUBZONE.VIEW])
          }
          destination={ProductDestination.SUBZONE}
          manufacturerId={switchboard.manufacturerId}
        />
        <CheckUserPermissions
          permissions={[PermissionType.SWITCHBOARD.SUBZONE.MODIFY]}
        >
          <div
            className={`d-flex mt-4 ${isEdit ? 'justify-content-between' : ''}`}
          >
            <div>
              {subzone.isDeleted ? (
                <Button
                  type="button"
                  color="falcon-success"
                  onClick={restoreDeletedSubzone}
                  disabled={isZoneDeleted}
                >
                  {t('Restore')}
                </Button>
              ) : (
                isEdit && (
                  <Button
                    type="button"
                    disabled={isLoading}
                    color="falcon-danger"
                    onClick={showDeleteConfirmationToast}
                  >
                    {t('Delete')}
                  </Button>
                )
              )}
              {isZoneDeleted && (
                <p className="text-danger mt-4">
                  * Zone is deleted, you have to restore it first.
                </p>
              )}
            </div>
            {!checkIsDeleted && (
              <div>
                <Button
                  type="submit"
                  disabled={isLoading}
                  color="falcon-primary"
                >
                  {isLoading ? 'Saving' : 'Save'}
                </Button>
              </div>
            )}
          </div>
        </CheckUserPermissions>
      </Form>
    </FormProvider>
  );
}
