import axios from 'axios';
import Cookies from 'js-cookie';

import { config } from '../../config';
import { HttpResponseStatus } from '../../constants/httpResponseStatus';
import { memoizedRefreshToken } from './refreshToken';

const instance = axios.create({
  baseURL: config.rootApiUrl
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    const tokenType = Cookies.get('tokenType');

    if (token && tokenType) {
      config.headers.Authorization = `${tokenType} ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (
      error?.response?.status === HttpResponseStatus.Unauthorized &&
      !config?.sent
    ) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.token) {
        config.headers.Authorization = `${result.tokenType} ${result.token}`;
      }

      return instance(config);
    }
    return Promise.reject(error);
  }
);

export default instance;
