import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import AppContext from '../../../context/Context';
import { productApi } from '../../../helpers/api/productApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { ProductGroup } from '../../../helpers/enums/productGroupEnum';
import { productFormSchema } from '../../../helpers/formValidations/productFormSchema';
import { useFormScrollIntoView } from '../../../hooks/useFormScrollIntoView';
import CableRelatedFields from './CableRelatedFields';
import GroundingSelect from './GroundingSelect';
import ProductFormMainFields from './MainFields';
import DifferentialInputs from './MainFields/DifferentialInputs';
import ProtectionRelatedFields from './ProtectionRelatedFields';
import SwitchBoardRelatedFields from './SwitchBoardRelatedFields';

export default function ProductModalForm({
  defaultValues,
  isLoading,
  setIsLoading
}) {
  const { t } = useTranslation();
  const { editProductId, toggleProductModal, setFetchData } =
    useContext(AppContext);
  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(productFormSchema)
  });

  useFormScrollIntoView({ form });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  const onSave = async (data) => {
    setIsLoading(true);
    try {
      if (editProductId) {
        await productApi.editProduct(editProductId, data);
      } else {
        await productApi.addProduct(data);
      }
      toast.success(t('Product successfully saved!'));
      toggleProductModal();
      form.reset();
      setFetchData(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!editProductId) {
      form.reset(defaultValues);
    }
  }, [defaultValues, editProductId, form]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSave)}>
        <div className="form-modal-padding">
          <ProductFormMainFields isLoading={isLoading} />
          <DifferentialInputs isLoading={isLoading} />
          <ProtectionRelatedFields isLoading={isLoading} />
          <SwitchBoardRelatedFields isLoading={isLoading} />
          <CableRelatedFields isLoading={isLoading} />
          {form.watch('group') === ProductGroup.Clamp && (
            <GroundingSelect isDisabled={isLoading} />
          )}
          <div className="d-flex justify-content-between align-items-end">
            <Button
              disabled={isLoading || isLoading}
              type="submit"
              color="falcon-primary"
            >
              {isLoading ? '...' : t('Save')}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
}
