import React from 'react';
import { Outlet } from 'react-router-dom';

import NotAuthComponent from '../screens/auth/components/NotAuthComponent';

const GuardedRoute = ({ auth }) => {
  return auth ? <Outlet /> : <NotAuthComponent />;
};

export default GuardedRoute;
