import i18n from '../../localization';
import axios from '../axios/axios.service';

export const projectApi = {
  getProjects: async (params) => {
    return await axios.get(`/projects`, { params });
  },
  getProjectById: async (id) => {
    return await axios.get(`/projects/${id}`);
  },
  addProject: async (data) => {
    return await axios.post(`/projects`, data);
  },
  deleteProject: async (id) => {
    return await axios.delete(`/projects/${id}`);
  },
  updateProject: async (id, data) => {
    return await axios.post(`/projects/${id}`, data);
  },
  updateProjectStatus: async (id, status) => {
    return await axios.post(`/projects/${id}/status`, { status });
  },
  getProjectOffer: async (projectId) => {
    return await axios.get(`/projects/${projectId}/offer`);
  },
  getProjectOfferEntries: async (projectId, params) => {
    return await axios.get(`/projects/${projectId}/offer-entries`, { params });
  },
  getProjectAttachments: async (projectId) => {
    return await axios.get(`/projects/${projectId}/attachments`);
  },
  addProjectAttachment: async (projectId, formData) => {
    return await axios.post(`/projects/${projectId}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },
  downloadProjectAttachment: async (projectId, fileId) => {
    return await axios.get(`/projects/${projectId}/attachments/${fileId}`, {
      responseType: 'blob'
    });
  },
  deleteProjectAttachment: async (projectId, fileId) => {
    return await axios.delete(`/projects/${projectId}/attachments/${fileId}`);
  },
  exportProject: async (projectId, format) => {
    return await axios.post(
      `/projects/${projectId}/export`,
      {
        documentationType: format
      },
      {
        responseType: 'blob'
      }
    );
  },
  generateSwitchboardDocumentation: async (projectId) =>
    await axios.post(
      `/projects/${projectId}/generate-switchboard-documentation/${i18n.language}`
    ),
  getDocumentationInfo: async (projectId) =>
    await axios.get(`/projects/${projectId}/switchboard-documentation-info`)
};
