import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import AppContext from '../../../context/Context';
import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { projectFormSchema } from '../../../helpers/formValidations/projectFormSchema';
import { useFormScrollIntoView } from '../../../hooks/useFormScrollIntoView';
import ClientSelect from '../ClientSelect';
import InputFormField from '../InputFormField';
import InputFormFieldFloatValue from '../InputFormFieldFloatValue';

export default function ProjectModalForm({
  defaultValues,
  isLoading,
  setIsLoading
}) {
  const { t } = useTranslation();
  const { editProjectId, toggleProjectModal, setFetchData } =
    useContext(AppContext);

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(projectFormSchema)
  });

  useFormScrollIntoView({ form });

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    try {
      if (editProjectId) {
        await projectApi.updateProject(editProjectId, data);
      } else {
        await projectApi.addProject(data);
      }
      toast.success(t('Project successfully created!'));
      setFetchData(true);
      toggleProjectModal();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <ClientSelect
          isDisabled={isLoading}
          isRequired
          setIsLoading={setIsLoading}
        />
        <InputFormField
          isDisabled={isLoading}
          isRequired
          label="name"
          displayLabel="Project Name"
        />
        <InputFormField
          isDisabled={isLoading}
          placeholder={'191-103 Integer Rd.\nCorona New Mexico 08219'}
          inputType="textarea"
          displayLabel="Address"
          label="address"
          numberOfRows={3}
        />
        <InputFormField
          isDisabled={isLoading}
          placeholder="Other information can be stored here"
          inputType="textarea"
          displayLabel="Notes"
          label="notes"
          numberOfRows={2}
        />
        <h5 className="mt-2">{t('Financial')}</h5>
        <InputFormFieldFloatValue
          label="hourlyRate"
          displayLabel="Hourly Rate"
          isDisabled={isLoading}
          inputGroupText={`EUR / ${t('hour')}`}
        />
        <InputFormFieldFloatValue
          label="discount"
          displayLabel="Discount"
          isDisabled={isLoading}
          inputGroupText="%"
        />
        <InputFormFieldFloatValue
          label="transportCost"
          displayLabel="Transport Cost"
          isDisabled={isLoading}
          inputGroupText="EUR"
        />
        <Button
          disabled={isLoading || isLoading}
          type="submit"
          color="falcon-primary"
        >
          {isLoading ? '...' : t('Save')}
        </Button>
      </Form>
    </FormProvider>
  );
}
