import z from 'zod';

export const registerFormSchema = z
  .object({
    email: z
      .string({
        invalid_type_error: 'Invalid email address',
        required_error: 'Email is required'
      })
      .email({
        message: 'Invalid email address'
      }),
    password: z
      .string({
        required_error: 'Password is required'
      })
      .min(6, {
        message: 'Password must be at least 6 characters long'
      })
      .regex(/[A-Z]+/g, {
        message: 'Password must contain at least one uppercase letter'
      })
      .regex(/[a-z]+/g, {
        message: 'Password must contain at least one lowercase letter'
      })
      .regex(/[0-9]+/g, {
        message: 'Password must contain at least one number'
      }),
    firstName: z
      .string({
        required_error: 'First name is required'
      })
      .min(1, {
        message: 'First name is required'
      }),
    lastName: z
      .string({
        required_error: 'Last name is required'
      })
      .min(1, {
        message: 'Last name is required'
      }),
    confirmPassword: z
      .string({
        required_error: 'Confirm password is required'
      })
      .min(6, {
        message: 'Confirm password must be at least 6 characters long'
      }),
    companyName: z.string().optional(),
    phoneNumber: z.string().optional(),
    captcha: z
      .string({
        required_error: 'Code is required',
        invalid_type_error: 'Code is required'
      })
      .min(1, {
        message: 'Code is required'
      })
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword']
  });
