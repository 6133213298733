import { showErrorsFromRequest } from '../api/showErrorsFromRequest';
import { userApi } from '../api/user/userApi';

export const getUserIdentity = async ({ setUser, setIsAuthenticated, t }) => {
  try {
    const response = await userApi.userIdentity();
    setUser({
      name: response.data.firstName,
      surname: response.data.lastName,
      email: response.data.email,
      isAdmin: response.data.isAdmin,
      permissions: response.data.permissions
    });
    setIsAuthenticated(true);
  } catch (error) {
    showErrorsFromRequest(error, t);
    setIsAuthenticated(false);
    setUser(null);
    return null;
  }
};
