import axios from '../axios/axios.service';

export const productApi = {
  getProducts: async (params) => {
    return await axios.get(`/products`, { params });
  },
  getProductsByDestination: async (params) => {
    return await axios.get(`/products/destination`, { params });
  },
  getProductById: async (id) => {
    return await axios.get(`/products/${id}`);
  },
  addProduct: async (product) => {
    return await axios.post(`/products`, product);
  },
  editProduct: async (id, product) => {
    return await axios.post(`/products/${id}`, product);
  },
  deleteProduct: async (id) => {
    return await axios.delete(`/products/${id}`);
  }
};
