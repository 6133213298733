import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileDownload from 'js-file-download';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Media } from 'reactstrap';

import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';

const SharedFile = ({ file, isLast, setUpdateAttachments }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { name, user, time } = file;

  const handleFileDownload = async () => {
    const formData = new FormData();

    formData.append('file', file);
    try {
      const response = await projectApi.downloadProjectAttachment(
        projectId,
        file.id
      );
      fileDownload(response.data, name);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const handleFileDelete = async () => {
    try {
      await projectApi.deleteProjectAttachment(projectId, file.id);
      toast.success(t('File deleted successfully'));
      setUpdateAttachments(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  return (
    <>
      <Media className="mb-3 hover-actions-trigger align-items-center">
        <Media body className="ml-3">
          <h6 className="mb-1">
            <Link
              className="stretched-link text-900 font-weight-semi-bold text-break"
              onClick={handleFileDownload}
            >
              {name}
            </Link>
          </h6>
          <div className="fs--1">
            <span className="font-weight-semi-bold">{user}</span>
            <span className="font-weight-medium text-600 ml-2">{time}</span>
          </div>
          <div className="hover-actions r-0 absolute-vertical-center">
            <Button
              size="sm"
              className="border-300 text-600 bg-danger"
              onClick={handleFileDelete}
            >
              <FontAwesomeIcon icon="trash" className="text-white" />
            </Button>
          </div>
        </Media>
      </Media>
      {!isLast && <hr className="border-200" />}
    </>
  );
};

export default SharedFile;
