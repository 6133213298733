import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import RequiredStarLabel from '../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../components/form/FormGroupNote';

export default function PasswordInput({
  label,
  displayLabel,
  isRequired,
  isDisabled
}) {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <FormGroup className="w-100">
      <Label htmlFor={label}>
        {t(displayLabel)} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={label}
        control={control}
        render={({ field }) => (
          <div className="position-relative">
            <Input
              {...field}
              placeholder={t('Password')}
              type={isPasswordVisible ? 'text' : 'password'}
              className="pr-5"
              autoComplete="new-password"
            />
            {field.value !== '' && (
              <Button
                type="button"
                className="position-absolute bg-transparent border-0 text-black t-0 r-0 d-flex align-items-center h-100"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEyeSlash : faEye}
                />
              </Button>
            )}
          </div>
        )}
      />
      {errors[label] && (
        <FormGroupNote type={'warning'}>{errors[label].message}</FormGroupNote>
      )}
    </FormGroup>
  );
}
