export const manageUsers = {
  en: {
    translations: {
      Users: 'Users',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Role: 'Role',
      'Add User': 'Add User'
    }
  },
  ro: {
    translations: {
      Users: 'Utilizatori',
      'First Name': 'Prenume',
      'Last Name': 'Nume',
      Role: 'Rol',
      'Add User': 'Adaugă un utilizator'
    }
  },
  de: {
    translations: {
      Users: 'Benutzer',
      'First Name': 'Vorname',
      'Last Name': 'Nachname',
      Role: 'Rolle',
      'Add User': 'Benutzer hinzufügen'
    }
  }
};
